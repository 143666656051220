document.addEventListener('DOMContentLoaded', function(){
  let alerts = document.querySelectorAll('.clickable-alert');
  alerts.forEach(function(alert) {
    alert.addEventListener('click', function(){
      this.style.display = 'none';
    });

    // 4秒後にフラッシュメッセージを消す
    setTimeout(function(){
      alert.style.display = 'none';
    }, 4000);
  });
});